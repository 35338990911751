export const menuItems = [
    {
        id: 1,
        label: "SITE",
        isTitle: true,
        permission: "view_site",  // Added permission
    },
    {
        id: 2,
        label: "Home",
        icon: "uil-home-alt",
        badge: {
            variant: "warning",
            text: "GO"
        },
        link: "/",
        permission: "view_site",  // Added permission
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "uil-bag",
        badge: {
            variant: "warning",
            text: "Overview"
        },
        link: "/Dashboard",
        permission: "view_site",  // Added permission
    },
    {
        id: 3,
        label: "menuitems.apps.text",
        isTitle: true,
        permission: "view_contact",  // Example permission
    },
    {
        id: 15,
        label: "Contacts",
        icon: "uil-building",
        subItems: [
            {
                id: 18,
                label: "Addressbook",
                link: "/humanresource/parts/Addressbook",
                parentId: 15,
                permission: "view_addressbook",  // Added permission
            },
            {
                id: 17,
                label: "menuitems.ecommerce.list.customers",
                link: "/humanresource/customers",
                parentId: 15,
                permission: "view_contact",  // Example permission
            },
            {
                id: 16,
                label: "Employees",
                link: "/contacts/list",
                parentId: 15,
                permission: "view_employee",  // Added permission
            },
            {
                id: 19,
                label: "Suppliers",
                link: "/humanresource/Suppliers",
                parentId: 15,
                permission: "view_contact",  // Example permission
            },
        ]
    },
    {
        id: 6,
        label: "Purchases",
        icon: "uil-shopping-bag",
        subItems: [
            {
                id: 5,
                label: "List Purchases",
                link: "/purchases/purchases",
                parentId: 6,
                permission: "view_purchase",  // Added permission
            },
            {
                id: 6,
                label: "Purchase Returns",
                link: "/purchases/purchase_returns",
                parentId: 6,
                permission: "view_purchasereturn",  // Added permission
            },
        ]
    },
    {
        id: 6,
        label: "Manage Products",
        icon: "uil-cell",
        subItems: [
            {
                id: 5,
                label: "menuitems.ecommerce.list.products",
                link: "/ecommerce/products",
                parentId: 6,
                permission: "view_products",  // Added permission
            },
            {
                id: 6,
                label: "Manage Products",
                link: "/ecommerce/manage_products",
                parentId: 6,
                permission: "view_products",  // Added permission
            },
            {
                id: 7,
                label: "menuitems.ecommerce.list.addproduct",
                link: "/ecommerce/add-product",
                parentId: 6,
                permission: "add_products",  // Added permission
            },
            {
                id: 8,
                label: "Main Categories",
                link: "/ecommerce/product/MainCategories",
                parentId: 6,
                permission: "view_maincategory",  // Added permission
            },
            {
                id: 8,
                label: "Categories",
                link: "/ecommerce/product/Categories",
                parentId: 6,
                permission: "view_category",  // Added permission
            },
            {
                id: 9,
                label: "Sub Categories",
                link: "/ecommerce/product/SubCategories",
                parentId: 6,
                permission: "view_subcategory",  // Added permission
            },
            {
                id: 11,
                label: "Units",
                link: "/ecommerce/cart",
                parentId: 6,
                permission: "view_unit",  // Added permission
            },
            {
                id: 13,
                label: "Brands",
                link: "/ecommerce/shops",
                parentId: 6,
                permission: "view_productbrands",  // Added permission
            },
        ]
    },
    {
        id: 4,
        label: "Point Of Sale",
        icon: "uil-dollar-sign",
        subItems: [
            {
                id: 1,
                label: "POS",
                link: "/pos/index",
                parentId: 4,
                permission: "view_sales",  // Added permission
            },
            {
                id: 2,
                label: "All Sales",
                link: "/pos/sales",
                parentId: 4,
                permission: "view_sales",  // Added permission
            },
            {
                id: 3,
                label: "Quotations",
                link: "/pos/sales",
                parentId: 4,
                permission: "view_sales",  // Added permission
            },
            {
                id: 4,
                label: "Sales Returns",
                link: "/pos/sale_returns",
                parentId: 4,
                permission: "view_salesreturn",  // Added permission
            },
            {
                id: 5,
                label: "Shipments",
                link: "/pos/sales",
                parentId: 4,
                permission: "view_shipping",  // Added permission
            },
            {
                id: 6,
                label: "Discounts",
                link: "/pos/sales",
                parentId: 4,
                permission: "view_discounts",  // Added permission
            },
        ]
    },
    {
        id: 4,
        label: "Stock Transfer",
        icon: "uil-truck",
        subItems: [
            {
                id: 1,
                label: "Stock Transfers",
                link: "/stock/StockTransfers",
                parentId: 4,
                permission: "view_stocktransfer",  // Added permission
            },
        ]
    },
    {
        id: 4,
        label: "Stock Adjustments",
        icon: "uil-cell",
        subItems: [
            {
                id: 1,
                label: "List Adjustments",
                link: "/stock/stockadjustments",
                parentId: 4,
                permission: "view_stockadjustment",  // Added permission
            },
        ]
    },
    {
        id: 4,
        label: "Expenses",
        icon: "uil-minus-circle",
        subItems: [
            {
                id: 1,
                label: "List Expenses",
                link: "/expenses/expenses",
                parentId: 4,
                permission: "view_expense",  // Added permission
            },
            {
                id: 2,
                label: "Expense Categories",
                link: "/expenses/expense",
                parentId: 4,
                permission: "view_expensecategory",  // Added permission
            },
        ]
    },
    {
        id: 4,
        label: "Payment Accounts",
        icon: "uil-bill",
        subItems: [
            {
                id: 6,
                label: "List Accounts",
                link: "/pos/index",
                parentId: 4,
                permission: "view_paymentaccounts",  // Added permission
            },
            {
                id: 7,
                label: "Balance Sheet",
                link: "/pos/index",
                parentId: 4,
                permission: "view_accounttypes",  // Added permission
            },
            {
                id: 8,
                label: "Trial Balance",
                link: "/pos/index",
                parentId: 4,
                permission: "view_accounttypes",  // Added permission
            },
            {
                id: 9,
                label: "Cash Flow",
                link: "/pos/index",
                parentId: 4,
                permission: "view_accounttypes",  // Added permission
            },
        ]
    },
    {
        id: 18,
        label: "Records",
        icon: "uil-invoice",
        subItems: [
            // {
            //     id: 19,
            //     label: "Invoices",
            //     link: "/documents/invoices",
            //     parentId: 18,
            //     permission: "view_order",  // Added permission
            // },
            {
                id: 9,
                label: "menuitems.ecommerce.list.orders",
                link: "/documents/orders",
                parentId: 18,
                permission: "view_order",  // Added permission
            },
            {
                id: 10,
                label: "M-pesa Transactions",
                link: "/ecommerce/mpesa/transactions",
                parentId: 18,
                permission: "view_mpesatransaction",  // Added permission
            },
        ]
    },
    {
        id: 2,
        label: "Reports",
        icon: "uil-file",
        badge: {
            variant: "warning",
            text: "Analytics"
        },
        link: "/report",
        permission: "view_reports",  // Added permission
    },
];
