let inactivityTimeout;
var user=sessionStorage.getItem("user")

function resetInactivityTimer() {
    clearTimeout(inactivityTimeout);
    if (user != null) {
        inactivityTimeout = setTimeout(logoutUser, 600000); // 10 minutes
    }
}

function logoutUser() {
    // Perform logout logic here (e.g., clear user data)
    this.$store.dispatch("authfack/logout");
    const currentRoute = window.location.href;
    // Check if the user is already on the login page
    if (currentRoute !== window.location.origin) {
        // Redirect the user to the login page
        window.location.href = window.location.origin; // Redirect to the login page
    }
}

// Add an event listener to reset the timer on user activity
window.addEventListener("mousemove", resetInactivityTimer);
window.addEventListener("keydown", resetInactivityTimer);

// Initialize the timer when the app loads or when the user logs in
resetInactivityTimer();
export default {
    resetInactivityTimer,
    logoutUser,
};