import { authHeader } from "./auth-header";
import store from "@/state/store";
import router from '../../router/index'
var CryptoJS = require("crypto-js");
import axios from 'axios';
// import Swal from "sweetalert2";

export const userService = {
    login,
    logout,
    register,
    getAll,
    data() {
        return {
            user_email: "",
        };
    },
};

function login(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
    };
    //var sanitizedScreens = [];
    return fetch(window.$http + "login/", requestOptions)
        .then(handleResponse)
        .then((user) => {
            let encryptToken = CryptoJS.AES.encrypt(
                user.user.token.trim(),
                "mnopqr",
            ).toString(); 
            sessionStorage.setItem("business_location",JSON.stringify(user.business));
            console.log(encryptToken)
            //fetch cart items
            axios.get(window.$http + `cart/?email=${email}`)
                .then(res => {
                    axios.get()
                    var items = res.data;
                    items.forEach(item => {
                        axios.get(window.$http + `stock/${item.stock_item}`)
                            .then(response => {
                                var stock = response.data;
                                var cartItem = {
                                    user: user.user.id,
                                    product: stock.product,
                                    stock_item: stock.id,
                                    quantity: item.quantity,
                                    item_subtotal: item.item_subtotal, // cart subtotal
                                    tax: item.tax_amount, // tax
                                    item_total: item.item_total,
                                    selling_price: item.selling_price,
                                };
                                store.dispatch("cart/addProductToCart", cartItem);
                            })
                            .catch(e => {
                                console.log(e);
                        })
                    });
                }).catch(error => {
                    console.log(error);
            })
            if (user.user.token) {
                let responseJson = {
                    id: user.user.id,
                    username: user.username,
                    name: user.user.fullname,
                    phone: user.user.phone,
                    email: user.user.email,
                    roles: user.roles,
                    token: user.user.token,
                };
                if (user.roles.includes('admin') || user.roles.includes('staff') ||user.roles.includes('manager')) {
                    console.log(user.roles);
                    sessionStorage.setItem("isadmin", true);
                }
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem("user", JSON.stringify(responseJson));
                sessionStorage.setItem("user_addresses", JSON.stringify(user.addresses));
                if (JSON.stringify(user.addresses).includes('address')) {
                    console.log(user.addresses)
                    sessionStorage.setItem("addresses", JSON.stringify(user.addresses));
                    store.dispatch('address/addAddresses', user.addresses);
                    var len = user.addresses.address.length;
                    console.log(len)
                    var addr = null;
                    if (len > 0) {
                        addr = user.addresses.address.find((e) => e.default_address === true);
                        if (addr !== null) {
                            console.log(addr)
                            store.dispatch('address/addDefaultAddresses', addr);
                            sessionStorage.setItem("default_address", JSON.stringify(addr))
                            axios
                                .get(window.$http + `pickup_stations?region=` + addr.address__region__region, {
                                    headers: {
                                        "Content-Type": ["application/json"],
                                        Authorization: 'Token ' + user.user.token,
                                    }
                                })
                                .then((response) => {
                                    store.dispatch('address/addDeliveryAddresses', response.data['results']);
                                    sessionStorage.setItem("delivery_addresses", response.data['results']);
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        } else {
                            addr = user.addresses.address[0];
                            store.dispatch('address/addDefaultAddresses', addr);
                            sessionStorage.setItem("default_address", JSON.stringify(addr));
                            axios
                                .get(window.$http + `delivery_addresses?region=` + addr.address__region__region, {
                                    headers: {
                                        "Content-Type": ["application/json"],
                                        Authorization: 'Token ' + user.user.token,
                                    }
                                })
                                .then((response) => {
                                    store.dispatch('address/addDeliveryAddresses', response.data['results']);
                                    sessionStorage.setItem("delivery_addresses", response.data['results']);
                                })
                                .catch((e) => {
                                    console.log(e);
                                });
                        }
                    } else {
                        store.dispatch('address/addDefaultAddresses', {});
                        sessionStorage.setItem("delivery_addresses", {});
                    }
                }
            }
            return user;
        })
        .then((error) => {
            return error;
        });
}

function logout() {
    // remove user from local storage to log user out
    axios.post(window.$http+`logout/`,{})
    .then(()=>{
    sessionStorage.clear();
    localStorage.clear()
    router.push('/login');
    }).catch((e)=>{
        console.log(e)
    })
}

function register(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
    };
    return fetch(window.$http + `register/`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        var data = text && JSON.parse(text);
        console.log(data)
        if (response.status !=200) {
            //var error = (data && data.message) || response.statusText;
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            return Promise.reject(data);
        }
        console.log(response);
        return data;
    });
}