<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <h4>Opening Stock</h4>
          <hr>
          <p><strong>Opening Stock (By purchase price):</strong> {{ this.formatCurrency(profitData.opening_stock_by_purchase_price) }}</p>
          <p><strong>Opening Stock (By sale price):</strong> {{ this.formatCurrency(profitData.opening_stock_by_sale_price) }}</p>
          <p><strong>Total purchase (Exc. tax, Discount):</strong> {{ this.formatCurrency(profitData.total_purchase_exc_tax_discount) }}</p>
          <p><strong>Total Stock Adjustment:</strong> {{ this.formatCurrency(profitData.total_stock_adjustment) }}</p>
          <p><strong>Total Expense:</strong> {{ this.formatCurrency(profitData.total_expense) }}</p>
          <p><strong>Total purchase shipping charge:</strong> {{ this.formatCurrency(profitData.total_purchase_shipping_charge) }}</p>
          <p><strong>Purchase additional expenses:</strong> {{ this.formatCurrency(profitData.purchase_additional_expenses) }}</p>
          <p><strong>Total transfer shipping charge:</strong> {{ this.formatCurrency(profitData.total_transfer_shipping_charge) }}</p>
          <p><strong>Total Sell discount:</strong> {{ this.formatCurrency(profitData.total_sell_discount) }}</p>
          <p><strong>Total customer reward:</strong> {{ this.formatCurrency(profitData.total_customer_reward) }}</p>
          <p><strong>Total Sell Return:</strong> {{ this.formatCurrency(profitData.total_sell_return) }}</p>
        </div>
        <div class="col-md-6">
          <h4>Closing Stock</h4>
          <hr>
          <p><strong>Closing stock (By purchase price):</strong> {{ this.formatCurrency(profitData.closing_stock_by_purchase_price) }}</p>
          <p><strong>Closing stock (By sale price):</strong> {{ this.formatCurrency(profitData.closing_stock_by_sale_price) }}</p>
          <p><strong>Total Sales (Exc. tax, Discount):</strong> {{ this.formatCurrency(profitData.total_sales_exc_tax_discount) }}</p>
          <p><strong>Total sell shipping charge:</strong> {{ this.formatCurrency(profitData.total_sell_shipping_charge) }}</p>
          <p><strong>Sell additional expenses:</strong> {{ this.formatCurrency(profitData.sell_additional_expenses) }}</p>
          <p><strong>Total Stock Recovered:</strong> {{ this.formatCurrency(profitData.total_stock_recovered) }}</p>
          <p><strong>Total Purchase Return:</strong> {{ this.formatCurrency(profitData.total_purchase_return) }}</p>
          <p><strong>Total Purchase discount:</strong> {{ this.formatCurrency(profitData.total_purchase_discount) }}</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <h4>Gross Profit: <strong>{{ this.formatCurrency(profitData.gross_profit) }}</strong></h4>
          <p class="text-muted">(Total sell price - Total purchase price)</p>
          <hr>
        </div>
        <div class="col-md-6">
          <h4>Net Profit: <strong>{{ this.formatCurrency(profitData.net_profit) }}</strong></h4>
          <p class="text-muted">(Gross Profit + (Total sell shipping charge + Sell additional expenses + Total Stock Recovered + Total Purchase discount + Total sell round off) - (Total Stock Adjustment + Total Expense + Total purchase shipping charge + Total transfer shipping charge + Purchase additional expenses + Total Sell discount + Total customer reward))</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/Axiosconfig"
  export default {
    data() {
      return {
        profitData:null,
      };
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      fetchData() {
        axios.get(`sale_overview/`)
        .then((response)=>{
            this.profitData=response.data;
        }).catch((e)=>{
            console.log(e)
        })
      },
      formatCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        }).format(amount);
      },
    }
  };
  </script>
  
  <style>
  /* Add your custom styles
  