import axios from 'axios';
//var CryptoJS = require("crypto-js");
//API ENDPOINT CONFIG
var href = window.location.href;
var arr = href.split("/");
var arr2 = arr[2].split(":");

window.$localurl = arr2[0] + "";
var ref = arr[0] + "//" + arr2[0] + ":8000";
var baseURL =ref + "/api/";
window.$http = ref + "/api/";
//window.$http = "https://posapi.yogisdelight.co.ke:8000/api/";
baseURL = "https://posapi.kwaboi.co.ke/api/";

var tokenString = "";
// try {
    if (sessionStorage.user != null) {
        // tokenString = CryptoJS.AES.decrypt(
        //     JSON.parse(sessionStorage.user).user.token,
        //     "mnopqr",
        // )
        //     .toString(CryptoJS.enc.Utf8)
        //     .trim();
        tokenString=JSON.parse(sessionStorage.user).user.token;
    }
// } catch (e) {
//     tokenString = "";
// }
console.log(tokenString);
const instance = axios.create({
    baseURL: baseURL,
    timeout: 360000, // 6 mins timeout
    headers: {
        "Content-Type": ["application/json"],
        Authorization: 'Token ' + tokenString,
    },
});

export default instance;
